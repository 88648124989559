import './App.css';

function App() {
  return (
    <div className="dashboard">
      <div className="main-page">
        <h2>Welcome to ThisPolo.net!</h2>
        <div className="message"> This website is currently WIP... You can find our first game <b>Printersim</b> over on <a href="https://store.steampowered.com/app/1665200/Printersim/">Steam</a>!</div>
      </div>
    </div>
  );
}

export default App;
